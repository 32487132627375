<template>
  <div>
    <AppHeaders />
    <div>
      <router-view />
    </div>
    <AppFooters />
  </div>
</template>

<script>
export default {
  name: 'Layout',
  components: {
    AppHeaders: () => import('./AppHeader'),
    AppFooters: () => import('./AppFooter'),
  },
  mounted() {
    // //api site get all data
    // function parseLabels(labels) {
    //   let labelMap = new Map();
    //   labels.map((label) => {
    //     if (labelMap.has(label.labelKey)) {
    //       labelMap.get(label.labelKey).push(label);
    //     } else {
    //       labelMap.set(label.labelKey, [label]);
    //     }
    //   });
    //   let keys = labelMap.keys();
    //   let key = keys.next();
    //   let labelList = [];
    //   while (!key.done) {
    //     let label1 = labelMap.get(key.value);
    //     const t = label1.reduce(
    //       (total, cur, index) => {
    //         let prefix = cur.languageType;
    //         total[prefix + 'Title'] = cur.title;
    //         total.labelButtons.push(
    //           ...cur.buttons.map((button) => {
    //             return {
    //               [prefix + 'Title']: button.label,
    //               url: button.url,
    //             };
    //           }),
    //         );
    //         return total;
    //       },
    //       { labelButtons: [] },
    //     );
    //     labelList.push(t);
    //     key = keys.next();
    //   }
    //   return labelList;
    // }
    // this.$store
    //   .dispatch('headerModule/getAllSite')
    //   .then(() => {
    //     const site = this.$store.getters['headerModule/getSite'];
    //     let data = {};
    //     data.phone = { phoneNumber: site.header.phone };
    //     data.menus = site.menus;
    //     data.areaSetting = site.areaSetting;
    //     data.banners = site.banners;
    //     data.logo = { link: site.header.logo };
    //     data.footer = {
    //       icons: site.footer.others.icons,
    //       copyright: site.footer.others.copyright,
    //       phoneNumber:
    //         site.footer.others.contactInfos[0] &&
    //         site.footer.others.contactInfos[0].hotLine,
    //       label1: {},
    //       label2: {},
    //       label3: {},
    //     };
    //     site.footer.others.contactInfos.forEach((ele) => {
    //       if (ele.languageType == 'en') {
    //         data.footer.enInformation = ele.contactDescription;
    //         data.footer.enTitle = ele.contactLabel;
    //       } else if (ele.languageType == 'cn') {
    //         data.footer.cnInformation = ele.contactDescription;
    //         data.footer.cnTitle = ele.contactLabel;
    //       } else {
    //         data.footer.hkInformation = ele.contactDescription;
    //         data.footer.hkTitle = ele.contactLabel;
    //       }
    //     });
    //     site.footer.labels.forEach((item, index) => {
    //       if (item.labelKey == 'Label1') {
    //         if (item.languageType == 'en') {
    //           data.footer.label1.enTitle = item.title;
    //           data.footer.label1.enBtns = item.buttons;
    //         } else if (item.languageType == 'cn') {
    //           data.footer.label1.cnTitle = item.title;
    //           data.footer.label1.cnBtns = item.buttons;
    //         } else {
    //           data.footer.label1.hkTitle = item.title;
    //           data.footer.label1.hkBtns = item.buttons;
    //         }
    //       } else if (item.labelKey == 'Label2') {
    //         if (item.languageType == 'en') {
    //           data.footer.label2.enTitle = item.title;
    //           data.footer.label2.enBtns = item.buttons;
    //         } else if (item.languageType == 'cn') {
    //           data.footer.label2.cnTitle = item.title;
    //           data.footer.label2.cnBtns = item.buttons;
    //         } else {
    //           data.footer.label2.hkTitle = item.title;
    //           data.footer.label2.hkBtns = item.buttons;
    //         }
    //       } else {
    //         if (item.languageType == 'en') {
    //           data.footer.label3.enTitle = item.title;
    //           data.footer.label3.enBtns = item.buttons;
    //         } else if (item.languageType == 'cn') {
    //           data.footer.label3.cnTitle = item.title;
    //           data.footer.label3.cnBtns = item.buttons;
    //         } else {
    //           data.footer.label3.hkTitle = item.title;
    //           data.footer.label3.hkBtns = item.buttons;
    //         }
    //       }
    //     });
    //     let labels = parseLabels(site.footer.labels);
    //     // console.log(labels)
    //     data.footer.label1 = labels[0];
    //     data.footer.label2 = labels[1];
    //     data.footer.label3 = labels[2];
    //     // let arr1 = []
    //     // let arr2 = []
    //     // let arr3 = []
    //     // data.footer.label1.cnBtns.forEach(item => {
    //     //   let o = {cnTitle:item.label,url:item.url}
    //     //   arr1.push(o)
    //     // })
    //     // data.footer.label1.enBtns.forEach((item,ien1) => {
    //     //   arr1[ien1].enTitle = item.label
    //     // })
    //     // data.footer.label1.hkBtns.forEach((item,ihk1) => {
    //     //   arr1[ihk1].hkTitle = item.label
    //     // })
    //     // data.footer.label2.cnBtns.forEach(item => {
    //     //   let o = {cnTitle:item.label,url:item.url}
    //     //   arr2.push(o)
    //     // })
    //     // data.footer.label2.enBtns.forEach((item,index) => {
    //     //   arr2[index].enTitle = item.label
    //     // })
    //     // data.footer.label2.hkBtns.forEach((item,index) => {
    //     //   arr2[index].hkTitle = item.label
    //     // })
    //     // data.footer.label3.cnBtns.forEach(item => {
    //     //   let o = {cnTitle:item.label,url:item.url}
    //     //   arr3.push(o)
    //     // })
    //     // data.footer.label3.enBtns.forEach((item,index) => {
    //     //   arr3[index].enTitle = item.label
    //     // })
    //     // data.footer.label3.hkBtns.forEach((item,index) => {
    //     //   arr3[index].hkTitle = item.label
    //     // })
    //     // data.footer.label1.labelButtons = arr1
    //     // data.footer.label2.labelButtons = arr2
    //     // data.footer.label3.labelButtons = arr3
    //     const sitedata = data;
    //     localStorage.setItem('site', JSON.stringify(sitedata));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     this.errored = true;
    //   })
    //   .finally(() => (this.loading = false));
  },
};
</script>
